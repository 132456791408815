@import "./variables";

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap.scss';



html,
body,
#root {
    height: 100%;
}

.btn {

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

a {

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.auth{
	.auth-background-holder {
		height: 100vh;
		min-height: 100%;
	}
}

.auth-main-col, .auth-background-col{
	position: relative;
}

.auth-main-col {
	margin: auto;
}

.auth-background-col {
	background: rgba($theme-text-color-primary, 0.6);
}

.auth-background-mask {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10;
	background: rgba(246, 102, 172, 1);
}

.auth-wrapper {
	background: white;
	height: 100vh;
	
	.auth-body {
		width: 360px;
	}
	
	.auth-heading {
		font-size: 1.5rem;
	}
	
	.auth-option {
		font-size: 0.875rem;
		> a {
			color: $theme-text-color-secondary
		}
	}

	.reset-password {
		> a {
			color: $theme-text-color-secondary
		}
	}

	input:focus {
		box-shadow: none;
	}
}

input::placeholder {
    font-family: 'Montserrat';
	font-size: 16px;
}

@media (max-width: 767.98px) { 
	.auth-background-col {
		display: none;
	}	
}